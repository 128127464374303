import iCodeReview from "types/iCodeReview";

export default class Model {
  private readonly BASE_URL = "https://vast-reef-90877.herokuapp.com/api/";

  // Methods
  public async fetchExercise(id: string): Promise<Object> {
    const url = this.BASE_URL + "code-labs/" + id;
    const exercise = await fetch(url).then((response) => response.json());

    return exercise;
  }

  public async review(codeToReview: iCodeReview): Promise<Object> {
    const url = this.BASE_URL + "submissions";
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(codeToReview),
    };
    const review = await fetch(url, config).then((response) => response.json());

    return review;
  }
}
