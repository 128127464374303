import { atom } from "recoil";

/*
About;
This file handles the user profile.
Each time the user logins, the atom is populated.
This if the App.jsx should render the login or logout pages.
*/

// Atoms
export const userState = atom({
  key: "user",
  default: null, // an object with the keys name, sda iteration, id, etc.
});
