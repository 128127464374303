import firebase from "utils/firebase";

export default class Model {
  // Methods
  public async fetchVideos(id: string): Promise<any> {
    const database = firebase.firestore();
    const snapshot = await database.collection("video-playlist").doc(id).get();
    const document = snapshot.data();

    return document;
  }
}
