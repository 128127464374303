import iFile from "types/iFile";
import firebase from "utils/firebase";

export default class Model {
  // Properties
  private _database = firebase.firestore();
  private _course = "";

  // Contructor
  constructor(course: string) {
    this._course = course;
  }

  // Methods
  private getQuery() {
    return this._database
      .collection("courses")
      .doc(this._course)
      .collection("content");
  }

  public async fetchLinks(): Promise<Array<iFile>> {
    const collection = await this.getQuery().get();
    const linksWithIds = collection.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as iFile)
    );

    return linksWithIds;
  }

  public createItem(item: iFile) {
    return this.getQuery().add(item);
  }

  public async updateItem(item: iFile) {
    return this.getQuery().doc(item.id).set(item);
  }

  public async deleteItem(id: string) {
    return this.getQuery().doc(id).delete();
  }
}
