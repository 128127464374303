// Internal
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "utils/fontawesome";

export default function Select({ props, state }) {
  // State
  const [stateValue, stateSetter] = state;

  // Properties
  const { label, options } = props;

  // Components
  const Options = options.map((item, index) => {
    return (
      <option key={index} value={item.value}>
        {item.label}
      </option>
    );
  });

  return (
    <fieldset>
      <label>{label}</label>

      {/* To add back the arrow on the right that is removed when we stylize the CSS to match the <input type=""> field */}
      <div className="select-wrapper">
        <select
          name="icon"
          value={stateValue}
          onChange={(event) => stateSetter(event.target.value)}
        >
          {Options}
        </select>
        <div className="icon">
          <FontAwesomeIcon icon="chevron-down" />
        </div>
      </div>
    </fieldset>
  );
}
