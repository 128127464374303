// Core
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

// Intenral
import Input from "components/Input";
import Logo from "assets/images/logo.svg";
import Illustration from "assets/images/illustrations/login.png";
import { userState } from "state/userState";
import Model from "./Model";

export default function Login() {
  // State
  const [, setUser] = useRecoilState(userState);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  // Properties
  const history = useHistory();
  const model = new Model();
  const inputFields = {
    email: {
      autoFocus: true,
      autoComplete: "user",
      id: "email",
      label: "Email",
      placeholder: "hannah@email.com",
      required: true,
      type: "email",
    },
    password: {
      autoComplete: "password",
      id: "password",
      label: "Password",
      type: "password",
      placeholder: "********",
      required: true,
    },
  };

  // Methods
  useEffect(() => {
    document.title = "SDA Login";
  });

  function onLogin(event) {
    setIsDisabled(true);
    event.preventDefault();
    model
      .login(email, password)
      .then((user) => onSucess(user))
      .catch((error) => onFailure(error));
  }

  function onSucess(user) {
    const id = user.id;

    setUser(user);
    localStorage.setItem("userId", id);
    history.push("/");
  }

  function onFailure(error) {
    console.error("Error", error);
    alert(error.message);
    setIsDisabled(false);
  }

  return (
    <div id="login" className="dual-hero">
      {/* Hero */}
      <section className="column column-hero">
        <div className="hero">
          <img
            src={Illustration}
            alt="People dancing around as a celebration"
          />
          <h1>Login to continue studying</h1>
        </div>
      </section>

      {/* Form */}
      <section className="column column-form">
        <form onSubmit={onLogin}>
          <img
            className="logo"
            src={Logo}
            alt="A circle with the letters S, D, and A inside"
          />
          <p>Welcome back. Please login to your account</p>
          <Input props={inputFields.email} state={[email, setEmail]} />
          <Input props={inputFields.password} state={[password, setPassword]} />
          <br />
          <input
            className="button"
            type="submit"
            value="Login"
            disabled={isDisabled}
          />
          <br />
          <br />
          <Link className="link-button" to={"recover-password"}>
            Forgot password?
          </Link>
          <br />
          <br />
          <Link className="link-button" to={"sign-up"}>
            Create a new account
          </Link>
        </form>
      </section>
    </div>
  );
}
