// Core
import { Link } from "react-router-dom";

export default function Anchor({
  children,
  className,
  href,
  isDisabled = false,
}) {
  // If the link is disabled, render as div
  if (isDisabled) {
    return <div className={className}>{children}</div>;
  }

  // Otherwise, render as a link or <a> depending of the link structure
  const isInternal = href[0] === "/";

  const LinkTag = (
    <Link className={className} to={href}>
      {children}
    </Link>
  );
  const AnchorTag = (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );

  return isInternal ? LinkTag : AnchorTag;
}
