// Core
import { useEffect, useState, useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Internal
import { courseIdState, selectedCourseState } from "state/courseState";
import Model from "./Model";
import Anchor from "components/Anchor";
import Video from "pages/video-playlist/Video";
import ItemVideo from "pages/video-playlist/ItemVideo";
import ModuleTitle from "components/ModuleTitle";
import Page from "components/Page";
import "./Style.sass";

export default function VideoPlaydata() {
  const dummyPlaylist = {
    id: "",
    additionalResources: "",
    courseId: "",
    description: "",
    name: "",
    list: [
      {
        youtubeCode: "",
        name: "",
        duration: "",
      },
    ],
  };

  // State
  const course = useRecoilValue(selectedCourseState);
  const [, setCourseId] = useRecoilState(courseIdState);
  const [videoPlaylist, setVideoPlaylist] = useState(dummyPlaylist);
  const [activeIndex, setActiveIndex] = useState(0);

  // Properties
  const { videoId } = useParams();
  const {
    additionalResources,
    courseId,
    description,
    list,
    name,
  } = videoPlaylist;

  // Methods
  const fetchVideos = useCallback(async () => {
    const model = new Model();
    const data = await model.fetchVideos(videoId);

    setVideoPlaylist(data);
  }, [setVideoPlaylist, videoId]);

  function onVideoChange(index) {
    window.scrollTo(0, 0);
    setActiveIndex(index);
  }

  function onVideoEnd() {
    window.scrollTo(0, 0);
    setActiveIndex(activeIndex + 1);
  }

  useEffect(() => {
    document.title = `SDA ${name} playlist`;

    setCourseId(courseId);
    fetchVideos();
  }, [fetchVideos, name, courseId, setCourseId]);

  // Components
  const VideoList = list.map((item, index) => {
    return (
      <ItemVideo
        active={index === activeIndex ? "active" : ""}
        name={item.name}
        duration={item.duration}
        index={index}
        key={index}
        onChange={onVideoChange}
      />
    );
  });

  return (
    <Page id="video-playlist">
      <section className="column column-video">
        <Video
          videoId={list[activeIndex].youtubeCode}
          onVideoEnd={onVideoEnd}
        />

        <ModuleTitle id={course.id} name={course.name} />
        <h1 className="title">{list[activeIndex].name}</h1>
        <p>{description}</p>
        <Anchor href={additionalResources}>
          <span className="icon">
            <FontAwesomeIcon icon="external-link-alt" />
          </span>
          Video additional recourses
        </Anchor>
      </section>

      <aside className="column column-list">
        <h2>Playlist</h2>
        {VideoList}
      </aside>

      <footer className="footer">
        <hr />
        <Link className="button" to={`/course/${course.id}`}>
          Back to course
        </Link>
      </footer>
    </Page>
  );
}
