// Core
import { Switch, Route } from "react-router-dom";

// Pages
import Login from "pages/login/Login";
import RecoverPassword from "pages/recover-password/RecoverPassword";
import SignUp from "pages/sign-up/SignUp";

export default function SwitchUnLogged() {
  return (
    <Switch>
      <Route path="/sign-up" component={SignUp} />
      <Route path="/recover-password" component={RecoverPassword} />
      <Route component={Login} />
    </Switch>
  );
}
