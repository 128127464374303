// Core
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "utils/fontawesome";

export default function Card({ props }) {
  const { image, name, date } = props;

  // Components
  const Padlock = (
    <div className="lock">
      <div className="icon">
        <FontAwesomeIcon icon="lock" />
      </div>
      <div>
        Starts on <br />
        {date}
      </div>
    </div>
  );

  return (
    <div className="card">
      <div className="image-container">
        {date && Padlock}
        <img className="image" src={image} alt={`${name} course cover`} />
      </div>
      <div className="label">{name}</div>
    </div>
  );
}
