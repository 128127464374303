// Internal
import ItemMaterial from "components/ItemMaterial";
import Anchor from "components/Anchor";

export default function ListMaterial({ list, type, title }) {
  const filterList = list.filter((item) => item.type === type);

  if (filterList.length === 0) return null;

  const Items = filterList.map((item) => {
    return (
      <Anchor
        className="item-material"
        href={item.link}
        isDisabled={item.date}
        key={item.id}
      >
        <ItemMaterial color={type} prop={item} />
      </Anchor>
    );
  });

  return (
    <>
      <h3>{title}</h3>
      <div className="list-material">{Items}</div>
    </>
  );
}
