// Internal
import NavigationBar from "components/NavigationBar";

export default function Page({ id, children }) {
  return (
    <div id={id} className="template">
      <NavigationBar />
      <div className="page-container">
        <div className="page">{children}</div>
      </div>
    </div>
  );
}
