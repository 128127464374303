// Core
import { useEffect, useState, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

// Internal
import Page from "components/Page";
import ItemMaterial from "../../components/ItemMaterial";
import Model from "./Model";
import "./Style.sass";

export default function CodeList() {
  // State
  const [links, setLinks] = useState([]);

  // Properties
  const history = useHistory();
  const { id } = useParams();

  // Methods
  const fetchLinks = useCallback(
    async (id) => {
      const model = new Model();
      const links = await model.fetchLinks(id);
      const sortedLinks = links.sort((a, b) => a.priority - b.priority);

      setLinks(sortedLinks);
    },
    [setLinks]
  );

  useEffect(() => {
    document.title = `Coding exercises`;

    fetchLinks(id);
  }, [fetchLinks]);

  function onGoBack() {
    history.goBack();
  }

  // Components
  const Links = links.map((item) => {
    return (
      <Link
        className="item-material"
        key={item.id}
        to={`/code-editor/${item.id}`}
      >
        <ItemMaterial prop={item} />
      </Link>
    );
  });

  return (
    <Page id="code-list">
      <header className="header">
        <h1 className="title">
          Code <br />
          Exercises
        </h1>
        <p className="description">
          Please click on any of the following buttons to practice your coding
          skills.
        </p>
      </header>

      {Links}

      <footer className="footer">
        <hr />
        <button className="button" onClick={onGoBack}>
          Back to course
        </button>
      </footer>
    </Page>
  );
}
