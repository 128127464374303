// Credit: https://www.digitalocean.com/community/tutorials/how-to-use-font-awesome-5-with-react
// Clean up imports by using: https://sortmylist.com

// Core
import { library } from "@fortawesome/fontawesome-svg-core";

// Icons
import { faGoogleDrive, faSlack } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowLeft,
  faBookOpen,
  faCalendarAlt,
  faChalkboardTeacher,
  faChartLine,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faCode,
  faCommentAlt,
  faExclamationCircle,
  faFileAlt,
  faFileCode,
  faFolder,
  faGlobe,
  faImage,
  faInfo,
  faLaptopCode,
  faLayerGroup,
  faListUl,
  faLock,
  faPencilAlt,
  faPlay,
  faPlus,
  faProjectDiagram,
  faTrashAlt,
  faTrophy,
  faUser,
  faUsers,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

library.add(faGoogleDrive, faSlack);
library.add(
  faArrowLeft,
  faBookOpen,
  faCalendarAlt,
  faChalkboardTeacher,
  faChartLine,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faCode,
  faCommentAlt,
  faExclamationCircle,
  faFileAlt,
  faFileCode,
  faFolder,
  faGlobe,
  faImage,
  faInfo,
  faLaptopCode,
  faLayerGroup,
  faListUl,
  faLock,
  faPencilAlt,
  faPlay,
  faPlus,
  faProjectDiagram,
  faTrashAlt,
  faTrophy,
  faUser,
  faUsers,
  faVideo
);
