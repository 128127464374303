// Core
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ItemVideo({ active, name, duration, onChange, index }) {
  return (
    <button className={`item-video ${active}`} onClick={() => onChange(index)}>
      <span className="icon">
        <FontAwesomeIcon icon="play" />
      </span>
      <span className="label">
        {index + 1}. {name}
      </span>
      <span className="duration">{duration}</span>
    </button>
  );
}
