import iAuthentification from "types/iAuthentification";
import firebase from "utils/firebase";

/*
 About:
 Firebase uses a different paradign than traditional databases. 
 Firebase has so many login options like Facebook, Twitter, Github, etc.
 That the user table could be ultra bloated even if you just want to store simple values like name, age, etc.
 Because each login method news its own information. (columns in a table)
 To fix this, Firebase stores the user information is in 2 separate files:
 1.- The user table that generates a unique ID per user (UID)
 2.- An individual file per user with the UID as the file name
*/

interface iUserData {
  code: string;
  id: string;
  name: string;
}

export default class Model {
  // Methods
  // Orquestrates the 2 step process of Firebase user account creation
  public async createAccount(credentials: iAuthentification): Promise<any> {
    const { name, email, password, code } = credentials;

    // 1. Create the user credential will return a UID.
    const userCredential = await this._userCredential(email, password);

    // 2. Store the user data elsewhere using the UID as the file name
    const uid: string = await userCredential.user.uid;
    const userData = { code: code, id: uid, name: name };
    const userFile = await this._userFile(uid, userData);

    console.log("Create account successfully", userFile);
    return userData;
  }

  private async _userCredential(email: string, password: string): Promise<any> {
    const auth = firebase.auth();
    const promise = auth.createUserWithEmailAndPassword(email, password);

    return promise;
  }

  private async _userFile(UID: string, userData: iUserData): Promise<void> {
    const database = firebase.firestore();
    const promise = database.collection("users").doc(UID).set(userData);

    return promise;
  }
}
