// Core
import { useState } from "react";

// Internal
import Input from "components/Input";
import Select from "components/Select";
import iconField from "./iconField.json";
import inputFields from "./inputFields.json";
import "./Form.sass";

export default function Form({ itemCreate, type }) {
  // Properties
  const defaultIconByLinkType = {
    document: "file-alt",
    slide: "layer-group",
    video: "play",
    coding: "laptop-code",
    workshop: "users",
    evaluation: "pencil-alt",
  };

  // State
  const [name, setName] = useState("");
  const [icon, setIcon] = useState(defaultIconByLinkType[type]);
  const [link, setLink] = useState("");
  const [date, setDate] = useState("");

  // Methods
  function onCreate(event) {
    const hasHTTPLink = link.startsWith("http://");
    const hasHTTPSLink = link.startsWith("https://");
    const parsedLink = hasHTTPLink || hasHTTPSLink ? link : `http://${link}`;
    const newFile = {
      id: "",
      priority: 0,
      type: type,
      name: name,
      icon: icon,
      link: parsedLink,
      date: date,
    };

    event.preventDefault();
    itemCreate(newFile);
    setName("");
    setLink("");
    setDate("");
  }

  return (
    <div className="form-item">
      <h4>Add new {type} file</h4>

      <form onSubmit={onCreate}>
        {/* Fields */}
        <Input props={inputFields.name} state={[name, setName]} />
        <Input props={inputFields.link} state={[link, setLink]} />
        <Select props={iconField} state={[icon, setIcon]} />
        <Input props={inputFields.date} state={[date, setDate]} />

        {/* Submit */}
        <input className="button" type="submit" value="Add link" />
      </form>
    </div>
  );
}
