// Core
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

// Internal
import Avatar from "components/Avatar";
import Page from "components/Page";
import { userState } from "state/userState";
import { codeAdmin } from "utils/authentificationCodes";
import "./Style.sass";

export default function Profile() {
  // State
  const [user, setUser] = useRecoilState(userState);

  // Properties
  const {
    name = "My name",
    iteration = "SDA 9",
    profile_picture,
    code = "not defined",
  } = user;
  const history = useHistory();

  // Methods
  useEffect(() => {
    document.title = "SDA Student profile";
  });

  function logout() {
    localStorage.clear();
    setUser(null);
    history.push("/");
  }

  return (
    <Page id="profile">
      <h1>
        User <br />
        Profile
      </h1>

      <h2>Personal information</h2>
      <Avatar picture={profile_picture} />
      <h3>{name}</h3>
      <span>{code === codeAdmin ? "Admin" : "Student"} profile</span>
      <span className="label">{iteration}</span>

      <footer className="footer">
        <hr />
        <button className="button" onClick={logout}>
          Logout
        </button>
      </footer>
    </Page>
  );
}
