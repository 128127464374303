// Core
import { useEffect } from "react";
import { Link } from "react-router-dom";

// Internal
import Illustration from "assets/images/illustrations/searching.png";
import "./Style.sass";

export default function Page404() {
  // Methods
  useEffect(() => {
    document.title = "SDA Page not found";
  });

  return (
    <div id="page404">
      <div className="content">
        <img
          src={Illustration}
          alt="Man using a magnifyng glass to look for clues"
        />

        <h1>Page not found</h1>

        <p>
          We are sorry, the page you are trying to see does not exist. Please
          check that the URL is typed correctly. Otherwise please contact us on
          our Slack channel to report any problem.
        </p>

        <Link className="button" to="/">
          Go back to courses
        </Link>
      </div>
    </div>
  );
}
