// Core
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Internal
import Input from "components/Input";
import Logo from "assets/images/logo.svg";
import Illustration from "assets/images/illustrations/searching.png";
import Model from "./Model";

export default function RecoverPassword() {
  // Properties
  const model = new Model();
  const emailField = {
    autoFocus: true,
    autoComplete: "user",
    id: "email",
    label: "Email",
    placeholder: "hannah@email.com",
    required: true,
    type: "email",
  };

  // State
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  // Page title
  useEffect(() => {
    document.title = "SDA Login";
  });

  // Methods
  function onPasswordRecovery(event) {
    setIsDisabled(true);
    event.preventDefault();

    model
      .recoverPassword(email)
      .then(() => onSucess())
      .catch((error) => onFailure(error));
  }

  function onSucess() {
    alert("We send you a email. Check your spam box as well.");
    setEmail("");
    setIsDisabled(false);
  }

  function onFailure(error) {
    const messageLine1 = `We are sorry, the email ${email} is not on our database. Please check again.`;
    const messageLine2 = `Othewise contact Sissi Rizko at rizko@kth.se for further help.`;

    console.error(error);
    alert(`${messageLine1}\n\n${messageLine2}`);
    setIsDisabled(false);
  }

  return (
    <div id="login" className="dual-hero">
      {/* Hero */}
      <section className="column column-hero">
        <div className="hero">
          <img
            src={Illustration}
            alt="People dancing around as a celebration"
          />
          <h1>Sometimes we forgot stuff</h1>
        </div>
      </section>

      {/* Form */}
      <section className="column column-form">
        <form onSubmit={onPasswordRecovery}>
          <img
            className="logo"
            src={Logo}
            alt="A circle with the letters S, D, and A inside"
          />
          <p>
            If you forgot your password, enter the email used to create the
            account.
          </p>

          <Input props={emailField} state={[email, setEmail]} />
          <br />
          <input
            className="button"
            type="submit"
            value="Recover account"
            disabled={isDisabled}
          />
          <br />
          <br />
          <Link className="link-button" to="/login">
            Go back to login
          </Link>
        </form>
      </section>
    </div>
  );
}
