// Core
import { useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";
import { Switch, Route } from "react-router-dom";

// Internal
import { coursesState } from "state/courseState";
import { userState } from "state/userState";
import { codeAdmin } from "utils/authentificationCodes";
import Model from "./Model";

// Pages
import CodeEditor from "pages/code-editor/CodeEditor";
import CodeList from "pages/code-list/CodeList";
import Course from "pages/course/Course";
import CourseEditor from "pages/course-editor/CourseEditor";
import CourseList from "pages/course-list/CourseList";
import HomeAdmin from "pages/home-admin/HomeAdmin";
import HomeStudent from "pages/home-student/HomeStudent";
import Login from "pages/login/Login";
import Page404 from "pages/page404/Page404";
import Profile from "pages/profile/Profile";
import RecoverPassword from "pages/recover-password/RecoverPassword";
import SignUp from "pages/sign-up/SignUp";
import VideoPlaylist from "pages/video-playlist/VideoPlaylist";

export default function SwitchLogged() {
  // State
  const [, setCourses] = useRecoilState(coursesState);
  const [user, setUser] = useRecoilState(userState);

  // Methods
  const fetchCourses = useCallback(
    async (model) => {
      const rawCourses = await model.getCourses();
      const sortedCourses = rawCourses.sort((a, b) => a.priority - b.priority);

      setCourses(sortedCourses);
    },
    [setCourses]
  );

  const fetchUser = useCallback(
    async (model) => {
      const completeUserProfile = await model.getUser(user.id);

      setUser(completeUserProfile);
    },
    [user.id, setUser]
  );

  useEffect(() => {
    const model = new Model();

    fetchCourses(model);
    fetchUser(model);
  }, [fetchCourses, fetchUser]);

  // Components
  // Refactor: Move to separate components for extra security.
  const SwitchStudent = (
    <Switch>
      <Route path="/" exact component={HomeStudent} />
      <Route path="/course/:id" component={Course} />
      <Route path="/login" component={Login} />
      <Route path="/profile" component={Profile} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/recover-password" component={RecoverPassword} />
      <Route path="/video-playlist/:videoId" component={VideoPlaylist} />
      <Route path="/code-list/:id" component={CodeList} />
      <Route path="/code-editor/:id" component={CodeEditor} />
      <Route component={Page404} />
    </Switch>
  );
  const SwitchAdmin = (
    <Switch>
      <Route path="/" exact component={HomeAdmin} />
      <Route path="/course/:id" component={Course} />
      <Route path="/course-list" component={CourseList} />
      <Route path="/course-edit/:courseId" component={CourseEditor} />
      <Route path="/login" component={Login} />
      <Route path="/profile" component={Profile} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/recover-password" component={RecoverPassword} />
      <Route path="/code-list/:id" component={CodeList} />
      <Route path="/code-editor/:id" component={CodeEditor} />
      <Route component={Page404} />
    </Switch>
  );

  return <>{user.code === codeAdmin ? SwitchAdmin : SwitchStudent}</>;
}
