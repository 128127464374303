// Core
import { Link } from "react-router-dom";

// Internal
import Page from "components/Page";
import Card from "components/Card";
import PictureAdmin from "assets/images/illustrations/course-list.png";
import PictureBooking from "assets/images/illustrations/book-schedule.png";
import PictureFolders from "assets/images/illustrations/gitlab-repositories.png";
import PictureGrading from "assets/images/illustrations/grade-students.png";
import "./HomeAdmin.sass";

export default function HomeAdmin() {
  // Properties
  const adminItems = [
    {
      id: 1,
      name: "Module contents",
      image: PictureAdmin,
      date: "",
      route: "course-list",
      type: "admin",
    },
  ];

  const taItems = [
    {
      id: 1,
      name: "Booking system",
      image: PictureBooking,
      date: "",
      route:
        "https://docs.google.com/spreadsheets/d/1IogGGC752olEdfGVV4Tf0Uagw_HtddSl_n95csrSzbU/edit#gid=0",
      type: "ta",
    },
    {
      id: 2,
      name: "Mentor tracker",
      image: PictureGrading,
      date: "",
      route:
        "https://docs.google.com/spreadsheets/d/12TFlud8GJxOmF2_CgPOBQadPcXi7fG2pX0y1Ob5A1KI/edit#gid=0",
      type: "ta",
    },
    {
      id: 3,
      name: "Additional information",
      image: PictureFolders,
      date: "",
      route:
        "https://docs.google.com/spreadsheets/d/1K1EuJ-uTbq6JGLloM-uSAjh2nUqQvFW-Wm4h4zimLo0/edit?usp=sharing",
      type: "ta",
    },
  ];

  // Components
  const CardsAnchor = ({ list }) => {
    return list.map((item) => {
      const unlockedLink = !item.date ? item.route : "#";

      return (
        <a href={unlockedLink} target="_blank">
          <Card key={item.id} props={item} />
        </a>
      );
    });
  };

  const CardsLink = ({ list }) => {
    return list.map((item) => {
      const unlockedLink = !item.date ? item.route : "#";

      return (
        <Link to={unlockedLink}>
          <Card key={item.id} props={item} />
        </Link>
      );
    });
  };

  return (
    <Page id="dashboard-admin">
      <h1>
        SDA Admin <br />
        Platform (v1.04)
      </h1>

      <h2>Admin task</h2>
      <div className="grid-container">
        <CardsLink list={adminItems} />
      </div>

      <h2>Teaching assistants task</h2>
      <div className="grid-container">
        <CardsAnchor list={taItems} />
      </div>
    </Page>
  );
}
