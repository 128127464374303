// Core
import { NavLink, Link } from "react-router-dom";
import { useRecoilState } from "recoil";

// Internal
import NavigationItem from "components/NavigationItem";
import Anchor from "components/Anchor";
import Logo from "assets/images/logo.svg";
import Avatar from "components/Avatar";
import { userState } from "state/userState";

export default function NavigationBar() {
  // State
  const [user] = useRecoilState(userState);

  // Properties
  const calendarURL =
    "https://calendar.google.com/calendar/u/2/r?cid=b2pxMDhuMGlrMWFnOGpjMzk0bmlqMGNjZmtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ";
  const slackURL = "slack://open?team=T01JJE0KAKX"; //
  const googleDriveURL =
    "https://drive.google.com/drive/folders/1CGXPHPj1Ddp_LOV5zHx4S6sd_CF-UPup?usp=sharing";
  const { profile_picture } = user;

  return (
    <nav className="navigation-bar">
      {/* Logo */}
      <Link to="/" className="logo">
        <img src={Logo} alt="An circle with the letters S, D, and A inside" />
      </Link>

      <hr />

      {/* Links */}
      {/* Go to home */}
      <NavLink
        className="item-navigation"
        activeClassName="selected"
        exact
        to="/"
      >
        <NavigationItem icon="book-open" label="Courses" />
      </NavLink>

      {/* Calendar */}
      <Anchor className="item-navigation" href={calendarURL}>
        <NavigationItem icon="calendar-alt" label="Calendar" />
      </Anchor>

      {/* Slack */}
      <Anchor className="item-navigation" href={slackURL}>
        <NavigationItem icon={["fab", "slack"]} label="Slack" />
      </Anchor>

      {/* Google Drive */}
      {user.code === "ADMIN_XYZ" && (
        <Anchor className="item-navigation" href={googleDriveURL}>
          <NavigationItem icon={["fab", "google-drive"]} label="G.Drive" />
        </Anchor>
      )}

      {/* User avatar */}
      <NavLink
        className="item-navigation profile"
        activeClassName="selected"
        to="/profile"
      >
        <NavigationItem icon="user" label="Profile" />
        <Avatar picture={profile_picture} />
      </NavLink>
    </nav>
  );
}
