// Core
import YouTube from "react-youtube";

export default function Video({ videoId, onVideoEnd }) {
  return (
    <div className="responsive-container">
      <YouTube
        className="responsive-iframe"
        videoId={videoId}
        onEnd={onVideoEnd}
      />
    </div>
  );
}
