// Core
import { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

// Internal
import Page from "components/Page";
import Model from "./Model";
import Section from "./Section";
import "./CourseEditor.sass";

export default function CourseEditor() {
  // State
  const [links, setLinks] = useState([]);

  // Properties
  const { courseId } = useParams();
  const model = new Model(courseId);

  // Methods
  const fetchLinks = useCallback(async () => {
    const unsortedLinks = await model.fetchLinks();
    const sortedLinks = unsortedLinks.sort((a, b) => a.priority - b.priority);

    setLinks(sortedLinks);
    window.scrollTo(0, 0);
  }, [model.fetchLinks, setLinks]);

  useEffect(() => {
    document.title = `SDA Edit module`;

    fetchLinks(courseId);
  }, [courseId, fetchLinks]);

  return (
    <Page id="course-detail">
      <h1>
        Edit
        <br />
        module
      </h1>

      <Link to={`/course/${courseId}`}>Preview</Link>

      <br />
      <br />

      {/* prettier-ignore */}
      <h2>Education material</h2>
      <>
        <Section
          model={model}
          rawLinks={links}
          title="Documents"
          type="document"
        />
        <Section model={model} rawLinks={links} title="Slides" type="slide" />
        <Section model={model} rawLinks={links} title="Videos" type="video" />
        <Section
          model={model}
          rawLinks={links}
          title="Workshops"
          type="workshop"
        />
        <Section model={model} rawLinks={links} title="Coding" type="coding" />
        <Section
          model={model}
          rawLinks={links}
          title="Evaluation projects"
          type="evaluation"
        />
      </>

      <footer className="footer">
        <hr />
        <Link className="button" to="/course-list">
          Back to modules
        </Link>
      </footer>
    </Page>
  );
}
