// Core
import { useState, useEffect } from "react";

// Internal
import Form from "./Form";
import Table from "./Table";

export default function Section({ model, rawLinks, title, type }) {
  // State
  const [filteredLinks, setFilteredLinks] = useState([]);

  // Methods
  function onCreate(newItem) {
    const length = filteredLinks.length;

    newItem.priority = length > 0 ? filteredLinks[length - 1].priority + 1 : 0;

    model
      .createItem(newItem)
      .then((documentRefence) => {
        newItem.id = documentRefence.id;
        setFilteredLinks([...filteredLinks, newItem]);
      })
      .catch((error) => console.error("Error", error));
  }

  function onUpdate(item) {
    model
      .updateItem(item)
      .then(console.log("Update sucess"))
      .catch((error) => alert.error(error.message));
  }

  function onDelete(itemId) {
    model
      .deleteItem(itemId)
      .then(() => {
        const updatedLinks = filteredLinks.filter((item) => item.id !== itemId);
        setFilteredLinks(updatedLinks);
      })
      .catch((error) => alert.error(error.message));
  }

  useEffect(() => {
    const filteredLinks = rawLinks.filter((item) => item.type === type);

    setFilteredLinks(filteredLinks);
  }, [rawLinks, setFilteredLinks]);

  return (
    <section>
      <h3>{title}</h3>
      <Table
        links={filteredLinks}
        itemUpdate={onUpdate}
        itemDelete={onDelete}
      ></Table>
      <Form type={type} itemCreate={onCreate} />
    </section>
  );
}
