import { atom, selector } from "recoil";
import { eCourseTpye } from "types/eCourseType";
import iCourse from "types/iCourse";

/*
About;
This file handles the course information. Each time a user chooses a course, we change the courseIdState (id).
Based on the id, the courseSelector automatically updates to have the information of that couse on hand. 
So we don't need to make a filter on each page that require only the current course information.
*/

// Refactor: Why we need to set a default course? The system should not break due to a empty course list, just show a empty state.
const defaultCourse: iCourse = {
  date: "",
  description: "",
  id: "",
  image: "",
  name: "",
  priority: 0,
  type: eCourseTpye.document,
};

// Atoms
/* ID of the currently selected course */
export const courseIdState = atom({
  key: "courseID",
  default: "programming-foundations",
});

/* Array with all the courses information */
export const coursesState = atom({
  key: "courses",
  default: [defaultCourse],
});

// Selectors
/* The current selected course by filtering courseState using courseIdState */
export const selectedCourseState = selector({
  key: "selectedCourse",
  get: ({ get }) => {
    const courses = get(coursesState);
    const id = get(courseIdState);
    const query = courses.filter((item) => item.id === id);
    const result = query.length === 0 ? defaultCourse : query[0];

    return result;
  },
});
