// Core
import { useState } from "react";

// Internal
import Input from "components/Input";
import Select from "components/Select";
import iconField from "./iconField.json";
import inputFields from "./inputFields.json";
import "./Row.sass";

export default function Row({ item, itemUpdate, itemDelete }) {
  // State
  const [priority, setPriority] = useState(item.priority);
  const [name, setName] = useState(item.name);
  const [link, setLink] = useState(item.link);
  const [date, setDate] = useState(item.date);
  const [icon, setIcon] = useState(item.icon);

  // Methods
  function onUpdate() {
    const newItem = {
      ...item,
      priority: priority,
      name: name,
      link: link,
      date: date,
      icon: icon,
    };

    itemUpdate(newItem);
  }

  function onDelete() {
    const confirm = window.confirm(`Are you sure you want to delete "${name}"`);

    if (confirm) itemDelete(item.id);
  }

  return (
    <tr className="row-item">
      <td>
        <Input props={inputFields.priority} state={[priority, setPriority]} />
      </td>
      <td>
        <Input props={inputFields.name} state={[name, setName]} />
      </td>
      <td>
        <Input props={inputFields.link} state={[link, setLink]} />
      </td>
      <td>
        <Select props={iconField} state={[icon, setIcon]} />
      </td>
      <td>
        <Input props={inputFields.date} state={[date, setDate]} />
      </td>
      <td>
        <button onClick={onUpdate}>Update</button>
        <button onClick={onDelete}>Delete</button>
      </td>
    </tr>
  );
}
