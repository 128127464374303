import firebase from "utils/firebase";
import iCourse from "types/iCourse";

export default class Model {
  // Methods
  public async fetchCourses(): Promise<Array<iCourse>> {
    const database = firebase.firestore();
    const collection = await database.collection("courses").get();
    const coursesWithId = collection.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as iCourse)
    );

    return coursesWithId;
  }
}
