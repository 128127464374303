import iCourse from "types/iCourse";
import firebase from "utils/firebase";

export default class Model {
  // Properties
  private readonly _database = firebase.firestore();

  // Methods
  public async getCourses(): Promise<Array<iCourse>> {
    const collection = await this._database.collection("courses").get();
    const coursesWithId = collection.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as iCourse)
    );

    return coursesWithId;
  }

  public async getUser(uid: string): Promise<Object> {
    const document = await this._database.collection("users").doc(uid).get();
    const profile = document.data() || {};

    return profile;
  }
}
