import firebase from "utils/firebase";
import iCodeExercises from "types/iCodeExercises";

export default class Model {
  // Methods
  public async fetchLinks(id: string): Promise<Array<iCodeExercises>> {
    const database = firebase.firestore();
    const collection = await database
      .collection("code-exercises")
      .doc(id)
      .collection("content")
      .get();
    const links = collection.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as iCodeExercises)
    );

    return links;
  }
}
