// Core
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

/*
 About:
 Firebase is our BaaS (Backend as a Service) provider. It has many features like Auth, Database, File storage, Hosting, etc that makes development easier.
 The initializeApp method contains our apiKey, domain, etc. Please read the official Firebase documentation for more info.

 Features used in this project:
 - Auth: For authenticating users.
 - FireStore: A noSQL database using individual JSON files to store information.
 - Storage: A cloud storage for uploading dynamic files like a user profile picture or an educational video.
 - Hosting: A solution to store static files, like the build of this app. Note: We don't import the hosting, instead we deploy files to the hosting.
*/

firebase.initializeApp({
  apiKey: "AIzaSyDeSVIm_d-p1K5iC5z7l1Bl0SsGVXc5qtU",
  authDomain: "sdaprojectest.firebaseapp.com",
  projectId: "sdaprojectest",
  storageBucket: "sdaprojectest.appspot.com",
});

export default firebase;
