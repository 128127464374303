// Core
import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

// Internal
import Page from "components/Page";
import Model from "./Model";
import "./Style.sass";

export default function CourseList() {
  // State
  const [courses, setCourses] = useState([]);

  // Methods
  const fetchCourses = useCallback(async () => {
    const model = new Model();
    const rawCourses = await model.fetchCourses();
    const sortedCourses = rawCourses.sort((a, b) => a.priority - b.priority);

    setCourses(sortedCourses);
  }, [setCourses]);

  useEffect(() => {
    document.title = `SDA Module editor`;

    fetchCourses();
  }, [fetchCourses]);

  // Components
  const CourseLinks = ({ list }) => {
    return list.map((item) => {
      return (
        <li key={item.id}>
          <Link to={`course-edit/${item.id}`}>
            <img src={item.image} alt={`${item.name} module cover`} />
            <p>{item.name}</p>
          </Link>
        </li>
      );
    });
  };

  return (
    <Page id="course-list">
      <h1>
        Module
        <br />
        contents
      </h1>

      <h2>Choose a module to edit</h2>

      <ul className="course-links">
        <CourseLinks list={courses} />
      </ul>

      <footer className="footer">
        <hr />
        <Link className="button" to="/">
          Back to home
        </Link>
      </footer>
    </Page>
  );
}
