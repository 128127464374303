// Core
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

// Internal
import { coursesState } from "state/courseState";
import Card from "components/Card";
import Page from "components/Page";
import "./HomeStudent.sass";

export default function HomeStudent() {
  // Properties
  const courses = useRecoilValue(coursesState);

  // Components
  const FilteredModules = ({ type }) => {
    const list = courses.filter((item) => item.type === type);

    return list.map((item) => {
      const unlockedLink = !item.date ? `course/${item.id}` : "#";

      return (
        <Link key={item.id} to={unlockedLink}>
          <Card props={item} />
        </Link>
      );
    });
  };

  return (
    <Page id="dashboard-student">
      <h1>Home</h1>

      <h2>Learning Modules</h2>
      <div className="grid-container">
        <FilteredModules type="learning" />
      </div>

      <h2>Evaluation Modules</h2>
      <div className="grid-container">
        <FilteredModules type="evaluation" />
      </div>
    </Page>
  );
}
