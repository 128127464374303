// Internal
import Row from "./Row";
import "./Table.sass";

export default function Table({ links, itemUpdate, itemDelete }) {
  // Components
  const EmptyState = <p>There is not links yet.</p>;
  const TableState = (
    <table>
      <thead>
        <tr>
          <th>Order</th>
          <th>Name</th>
          <th>Link</th>
          <th>Icon</th>
          <th>Unlock date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {links.map((item) => (
          <Row
            key={item.id}
            item={item}
            itemUpdate={itemUpdate}
            itemDelete={itemDelete}
          />
        ))}
      </tbody>
    </table>
  );

  return <>{links.length === 0 ? EmptyState : TableState}</>;
}
