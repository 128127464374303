// Core
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "utils/fontawesome";

export default function ItemNavigation({ icon, label }) {
  return (
    <>
      <div className="icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="label">{label}</div>
    </>
  );
}
