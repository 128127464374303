// Core
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useRecoilState } from "recoil";

// Internal
import SwitchLogged from "./pages/switch-logged/SwitchLogged";
import SwitchUnlogged from "./pages/switch-unlogged/SwitchUnlogged";
import { userState } from "state/userState";
import "styles/style.sass";

export default function App() {
  // State
  const [user, setUser] = useRecoilState(userState);

  // Methods
  useEffect(() => {
    const id = localStorage.getItem("userId");
    
    if (id) setUser({ id: id });
  }, [setUser]);

  return (
    <Router>
      <div className="App">
        {user && user.id ? <SwitchLogged /> : <SwitchUnlogged />}
      </div>
    </Router>
  );
}
