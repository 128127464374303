export default function Input({ props, state }) {
  const {
    autoComplete = false,
    autoFocus,
    id,
    label,
    placeholder,
    required,
    type,
  } = props;
  const [stateValue, stateSetter] = state;

  return (
    <fieldset className="field-set">
      <label htmlFor={id}>{label}</label>
      <input
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        id={id}
        placeholder={placeholder}
        required={required}
        type={type}
        value={stateValue}
        onChange={(event) => stateSetter(event.target.value)}
      />
    </fieldset>
  );
}
