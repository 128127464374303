import firebase from "utils/firebase";

export default class Model {
  // Methods
  // Orquestrates the 2 step process of Firebase user account creation
  public async login(email: string, password: string): Promise<any> {
    const userCredential = await this._authentificate(email, password);
    const uid: string = await userCredential.user.uid;
    const userFile = await this._userFile(uid);
    const userProfile = userFile.data();

    return userProfile;
  }

  private async _authentificate(email: string, password: string): Promise<any> {
    const auth = firebase.auth();
    const promise = auth.signInWithEmailAndPassword(email, password);

    return promise;
  }

  private async _userFile(UID: string): Promise<any> {
    const database = firebase.firestore();
    const promise = database.collection("users").doc(UID).get();

    return promise;
  }
}
