// Core
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { CodeFlaskReact } from "react-codeflask";
import { useHistory, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "utils/fontawesome";

// Internal
import Page from "components/Page";
import { userState } from "state/userState";
import Model from "./Model";
import MarkDownCodeHighlight from "./MarkDownCodeHighlight";
import MarkDownLinks from "./MarkDownLinks";
import "./Style.sass";

export default function CodeEditor() {
  // State
  const user = useRecoilValue(userState);
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [testStatus, setTestStatus] = useState(""); // in progress, pass, fail
  const [feedback, setFedback] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  // Properties
  const history = useHistory();
  const { id } = useParams();
  const reviewData = {
    name: id,
    code: code,
    userId: user.id,
  };

  // Methods
  const fetchExercise = useCallback(async () => {
    const model = new Model();
    const exercise = await model.fetchExercise(id);

    setDescription(exercise.readme);
    setCode(exercise.initialCode);
  }, [setCode, setDescription]);

  useEffect(() => {
    document.title = `Code exercise ${id}`;

    fetchExercise();
  }, [fetchExercise]);

  function onCodeChange(newCode) {
    setCode(newCode);
  }

  async function onReviewRequest() {
    setIsDisabled(true);
    setTestStatus("Reviewing code, please wait...");
    setFedback("");

    const model = new Model();
    const reviewResults = await model.review(reviewData);

    reviewComplete(reviewResults);
  }

  function onGoBack() {
    const message = confirm("Are you sure do you want to go back?");

    if (message) {
      history.goBack();
    }
  }

  function reviewComplete(data) {
    const titlePass = "Test passed 🎉 Go to the next challenge!";
    const titleFail = "Test failed. Read the reasons below and try again";

    setIsDisabled(false);
    setTestStatus(data.success ? titlePass : titleFail);
    setFedback(data.testResults[0].message);
  }

  return (
    <Page id="code-editor">
      {/* Objective and Instructions */}
      <ReactMarkdown
        className="code-description"
        renderers={{ link: MarkDownLinks, code: MarkDownCodeHighlight }}
      >
        {description}
      </ReactMarkdown>
      <br />

      {/* Code Editor */}
      <h2>Write your solution here</h2>
      <CodeFlaskReact code={code} onChange={onCodeChange} />

      {/* Feedback */}
      <h3>{testStatus}</h3>
      <ReactMarkdown renderers={{ code: MarkDownCodeHighlight }}>
        {feedback}
      </ReactMarkdown>

      <button
        className="button"
        disabled={isDisabled}
        onClick={onReviewRequest}
      >
        Run code
      </button>

      <footer className="footer">
        <hr />
        <button className="button-text" onClick={onGoBack}>
          <span className="icon">
            <FontAwesomeIcon icon={"arrow-left"} />
          </span>
          Back to exercises
        </button>
      </footer>
    </Page>
  );
}
