import firebase from "utils/firebase";
import iFile from "types/iFile";

export default class Model {
  // Methods
  public async fetchLinks(id: string): Promise<Array<iFile>> {
    const database = firebase.firestore();
    const collection = await database
      .collection("courses")
      .doc(id)
      .collection("content")
      .get();
    const links = collection.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as iFile)
    );

    return links;
  }
}
