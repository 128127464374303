// Core
import { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";

// Internal
import { courseIdState, selectedCourseState } from "state/courseState";
import Page from "components/Page";
import ListMaterial from "components/ListMaterial";
import Model from "./Model";
import "./Style.sass";

export default function Course() {
  // State
  const [links, setLinks] = useState([]);
  const [, setCourseId] = useRecoilState(courseIdState);
  const course = useRecoilValue(selectedCourseState);

  // Properties
  const { id } = useParams();
  const { name, description } = course;

  // Methods
  const fetchLinks = useCallback(
    async (id) => {
      const model = new Model();
      const links = await model.fetchLinks(id);
      const sortedLinks = links.sort((a, b) => a.priority - b.priority);

      setLinks(sortedLinks);
    },
    [setLinks]
  );

  useEffect(() => {
    document.title = `SDA ${name} Module`;

    setCourseId(id);
    fetchLinks(id);
  }, [id, setCourseId, fetchLinks, name]);

  return (
    <Page id="course">
      <header className="header">
        <h1 className={`title color-${id}`}>{name}</h1>
        <p className="description">{description}</p>
      </header>

      {/* prettier-ignore */}
      <section className="material-section">
        <ListMaterial list={links} title="Documents" type="document" />
        <ListMaterial list={links} title="Slides" type="slide" />
        <ListMaterial list={links} title="Videos" type="video" />
        <ListMaterial list={links} title="Workshops" type="workshop" />
        <ListMaterial list={links} title="Coding repositories" type="coding" />
        <ListMaterial list={links} title="Coding labs" type="code-exercises"/>
        <ListMaterial list={links} title="Evaluation" type="evaluation" />
      </section>

      <footer className="footer">
        <hr />
        <Link className="button" to="/">
          Back to courses
        </Link>
      </footer>
    </Page>
  );
}
