import firebase from "utils/firebase";

export default class Model {
  // Methods
  public async recoverPassword(email: string): Promise<void> {
    const auth = firebase.auth();
    const actionCode = await auth.sendPasswordResetEmail(email);

    // action code has the messages to inform the user if we found it's email in the database or not
    return actionCode;
  }
}
