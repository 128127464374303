// Core
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "utils/fontawesome";

export default function ItemMaterial({ color, prop }) {
  let { date = "", icon = "file-alt", name = "un-named file" } = prop;

  if (date != "") {
    color = "lock";
    icon = "lock";
  }

  const OpenDate = (
    <>
      <br />
      <small>(opens on {date})</small>
    </>
  );

  // The content of the component that always stay the same
  return (
    <>
      <span className={`icon color-${color}`}>
        <FontAwesomeIcon icon={icon} />
      </span>
      <span className="label">
        {name}
        {date ? OpenDate : null}
      </span>
    </>
  );
}
