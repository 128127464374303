// Core
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useRecoilState } from "recoil";

// Internal
import Logo from "assets/images/logo.svg";
import Illustration from "assets/images/illustrations/create-account.png";
import Input from "components/Input";
import { userState } from "state/userState";
import { codeAdmin, codeStudent } from "utils/authentificationCodes";
import Model from "./Model";

export default function SignUp() {
  // State
  const [, setUser] = useRecoilState(userState);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  // Properties
  const history = useHistory();
  const model = new Model();
  const inputFields = {
    name: {
      autoFocus: true,
      autoComplete: "name",
      id: "name",
      label: "Full name",
      type: "text",
      placeholder: "Hannah Bloomgberg",
    },
    email: {
      autoComplete: "user",
      id: "email",
      label: "Email",
      placeholder: "hannah@email.com",
      type: "email",
    },
    password: {
      autoComplete: "new-password",
      id: "password",
      label: "Password",
      type: "password",
      placeholder: "********",
    },
    code: {
      autoComplete: "",
      id: "code",
      label: "Activation code",
      type: "text",
      placeholder: "STCKHLM",
    },
  };

  // Methods
  useEffect(() => {
    document.title = "SDA Create admin account";
  });

  function verifyForm(event) {
    event.preventDefault();

    if (code === codeAdmin || code === codeStudent) onSubmit();
    else alert("The activation code is invalid");
  }

  function onSubmit() {
    const credentials = { name, email, password, code };

    setIsDisabled(true);
    model
      .createAccount(credentials)
      .then((result) => onSucess(result))
      .catch((error) => onFailure(error));
  }

  function onSucess(userData) {
    const id = userData.id;

    setUser(userData);
    localStorage.setItem("userId", id);
    history.push("/");
  }

  function onFailure(error) {
    console.error("Error", error);
    alert(error.message);
    setIsDisabled(false);
  }

  return (
    <div id="sign-up" className="dual-hero">
      {/* Hero (visible only on tablet landscape or higher) */}
      <section className="column column-hero">
        <div className="hero">
          <img
            src={Illustration}
            alt="People dancing around as a celebration"
          />
          <h1>Welcome to the SDA</h1>
          <p>
            This is the begining of your journey. You are about to learn what is
            necesary to become a suscessfull software developer
          </p>
        </div>
      </section>

      {/* Form (always available) */}
      <section className="column column-form">
        <form onSubmit={verifyForm}>
          <img
            className="logo"
            src={Logo}
            alt="A circle with the letters S, D, and A inside"
          />
          <p>
            Please enter the verification code send via email and personal data.
          </p>
          <Input props={inputFields.name} state={[name, setName]} />
          <Input props={inputFields.email} state={[email, setEmail]} />
          <Input props={inputFields.password} state={[password, setPassword]} />
          <Input props={inputFields.code} state={[code, setCode]} />
          <br />
          <input
            className="button"
            disabled={isDisabled}
            type="submit"
            value="Create account"
          />
          <br />
          <br />
          <Link className="link-button" to={"login"}>
            Login instead
          </Link>
        </form>
      </section>
    </div>
  );
}
